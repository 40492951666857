import React from 'react'

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 600 600"
    >
      <defs>
        <linearGradient id="linearGradient3068">
          <stop offset="0" stopColor="#70efff" stopOpacity="1" />
          <stop offset="1" stopColor="#5770ff" stopOpacity="1" />
        </linearGradient>
        <linearGradient id="linearGradient3013">
          <stop offset="0" stopColor="#0255fb" stopOpacity="1" />
          <stop offset="1" stopColor="#13ddf5" stopOpacity="1" />
        </linearGradient>
        <linearGradient id="linearGradient3915">
          <stop offset="0" stopColor="#0081f8" stopOpacity="1" />
          <stop offset="1" stopColor="#01ddff" stopOpacity="1" />
        </linearGradient>
        <linearGradient>
          <stop offset="0" stopColor="#0081f8" stopOpacity="1" />
          <stop offset="1" stopColor="#0081f8" stopOpacity="0" />
        </linearGradient>
        <linearGradient>
          <stop offset="0" stopColor="#3d73c3" stopOpacity="1" />
          <stop offset="0.25" stopColor="#0064f5" stopOpacity="0.373" />
          <stop offset="0.5" stopColor="#0064f5" stopOpacity="0.247" />
          <stop offset="1" stopColor="#0064f5" stopOpacity="0" />
        </linearGradient>
        <linearGradient>
          <stop offset="0" stopColor="#fff" stopOpacity="1" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          x1="377.888"
          x2="378.472"
          y1="512.689"
          y2="338.639"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient3915"
        />
        <linearGradient
          x1="305.201"
          x2="305.785"
          y1="598.592"
          y2="8.244"
          gradientTransform="translate(0 450.362)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient3013"
        />
        <linearGradient
          id="linearGradient3066"
          x1="305.201"
          x2="305.785"
          y1="598.592"
          y2="8.244"
          gradientTransform="matrix(.15 0 0 .15 .85 961.212)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient3068"
        />
      </defs>
      <g transform="translate(0 -450.362)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          color="#000"
          transform="matrix(6.66667 0 0 6.66667 -5.667 -5957.72)"
          visibility="visible"
        >
          <path
            style={{ marker: 'none' }}
            fill="url(#linearGradient3066)"
            strokeWidth="0.1"
            d="M21.653 961.362h48.694A20.607 20.607 0 0191 982.015v48.695a20.607 20.607 0 01-20.653 20.652H21.653A20.607 20.607 0 011 1030.71v-48.695a20.607 20.607 0 0120.653-20.653z"
            display="inline"
            enableBackground="accumulate"
            overflow="visible"
          />
          <path
            fill="#fff"
            strokeWidth="6.2"
            d="M20.719 536.594c-.474 0-.921.082-1.344.25l8.469 8.718 8.562 8.875.157.188.25.25.25.25.5.531 7.343 7.532c.123.076.477.404.754.542.356.178.743.343 1.141.357.43.015.87-.108 1.256-.296.29-.141.419-.343.755-.604l8.5-8.78 8.594-8.845 8.281-8.53a3.634 3.634 0 00-1.75-.438zm-2.594 1.062c-.903.856-1.469 2.142-1.469 3.594v28.625c0 1.175.378 2.243 1 3.063l1.188-1.125 8.843-8.594 7.844-7.594-.156-.188-8.594-8.843-8.593-8.875zm57.188.282l-8.376 8.656-8.562 8.843-.156.157 8.156 7.906 8.844 8.594.531.5c.476-.764.75-1.705.75-2.719V541.25c0-1.294-.449-2.468-1.188-3.313zM36.53 556.655l-7.812 7.594-8.875 8.594-1.125 1.093c.593.382 1.268.625 2 .625h51.718c.88 0 1.679-.338 2.344-.875l-.562-.562-8.875-8.594-8.156-7.875-7.344 7.563c-.398.263-.663.556-1.051.735-.625.289-1.31.533-1.998.523-.69-.011-1.366-.281-1.985-.586-.311-.152-.477-.304-.841-.61z"
            enableBackground="accumulate"
            transform="translate(0 450.362)"
            style={{ marker: 'none' }}
            display="inline"
            overflow="visible"
          />
        </g>
      </g>
    </svg>
  )
}

export default Email
