import React from 'react'
import PropTypes from 'prop-types'
import { Link, scroller } from 'react-scroll'
import './Header.css'

function Header(props) {
  const { toggleDarkMode, handleKeyUp, darkMode } = props

  return (
    <div className="header">
      <ul>
        <li><a href="." className="nav-link">anthonylau.dev</a></li>
        <div className="header-right">
          <li>
            <Link
              to="about"
              tabIndex={0}
              spy
              smooth
              duration={500}
              className="nav-link"
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  scroller.scrollTo('about', {})
                }
              }}
            >
              about
            </Link>
          </li>
          <li>
            <div onClick={toggleDarkMode} onKeyUp={handleKeyUp} role="button" tabIndex="0" className={darkMode ? 'lightmode-icon' : 'darkmode-icon'} aria-label="darkmode" />
          </li>
        </div>
      </ul>
      <div className="description">
        Hi, my name is Anthony. I am a Full Stack Developer living in Dallas, TX.
      </div>
    </div>

  )
}

Header.propTypes = {
  toggleDarkMode: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
}

export default Header
