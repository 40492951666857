import React from 'react'
import PropTypes from 'prop-types'
import { Motion, spring } from 'react-motion'
import { Link } from 'react-scroll'
import { range } from 'lodash'
import './Movement.css'
import GitHub from './icons/GitHubSVG'
import GitLab from './icons/GitLabSVG'
import LinkedIn from './icons/LinkedInSVG'
import Setting from './icons/SettingSVG'
import Email from './icons/EmailSVG'
import PawPrint from './icons/PawPrintSVG'
import Certificate from './icons/CertificateSVG'
import ChessHeatmap from './icons/ChessHeatmapSVG'

const screenIcons = [
  {
    svg: GitHub,
    backgroundColor: '#000',
    link: 'https://github.com/Anthony-Lau',
    name: 'Github',
  },
  {
    svg: LinkedIn,
    backgroundColor: '#fff',
    link: 'https://www.linkedin.com/in/the-anthony-lau',
    name: 'LinkedIn',
  },
  {
    svg: GitLab,
    backgroundColor: '#fff',
    link: 'https://gitlab.com/users/theanthonylau/projects',
    name: 'GitLab',
  },
  {
    svg: PawPrint,
    backgroundColor: '#fff',
    link: 'https://catandlilies.onrender.com/',
    name: 'C and L',
  },
  {
    svg: Certificate,
    backgroundColor: '#c9c4e1',
    link: 'https://www.youracclaim.com/users/anthony-lau',
    name: 'Certs',
  },
  {
    svg: ChessHeatmap,
    backgroundColor: '#BABABA',
    link: 'https://chessheatmap.netlify.app',
    name: 'ChessHeatmap',
  },
]

const dockIcons = [
  {
    svg: Email,
    backgroundColor: '',
    to: 'contact-element',
    name: 'Mail',
  },
  {
    svg: Setting,
    backgroundColor: 'darkgrey',
    link: 'https://anthonylau-psuedo-review.firebaseapp.com/',
    name: '',
  },
]

const icons = screenIcons.concat(dockIcons)

const springSetting1 = { stiffness: 180, damping: 10 }
const springSetting2 = { stiffness: 120, damping: 17 }

const reinsert = (arr, from, to) => { // reinsert into right places based on dock location
  const newArr = arr.slice(0)
  const val = newArr[from]
  newArr.splice(from, 1)
  newArr.splice(to, 0, val)
  return newArr
}

const clamp = (n, max) => Math.min(n, max)

const [count, columns] = [icons.length, 4]

const App = (props) => {
  const {
    lastPress,
    isPressed,
    mouseXY,
    order,
    index,
    handleMouseDown,
    Image,
    background,
    rotate,
    handleTouchStart,
    name,
    width,
    height,
    dockSize,
  } = props
  let style
  let x
  let y
  const visualPosition = order.indexOf(index)

  const layout = (numberInDock) => {
    const screen = icons.length - numberInDock
    const screenLayout = range(screen).map((n) => {
      const row = Math.floor(n / columns)
      const col = n % columns
      return [width * col, height * row]
    })
    const dockRow = 6.4
    const offset = 0.5
    const dockState = (newDock) => range(newDock).map((n) => {
      const col = (n + (2 - offset * newDock)) % columns
      return [width * col, height * dockRow]
    })
    const dockLayout = dockState(numberInDock)
    return screenLayout.concat(dockLayout)
  }

  const screenLayout = () => layout(dockSize)

  if (index === lastPress && isPressed) {
    [x, y] = mouseXY
    style = {
      translateX: x,
      translateY: y,
      scale: spring(1.2, springSetting1),
      rotation: rotate,
    }
  } else {
    [x, y] = screenLayout()[visualPosition]
    style = {
      translateX: spring(x, springSetting2),
      translateY: spring(y, springSetting2),
      scale: spring(1, springSetting1),
      rotation: rotate,
    }
  }

  return (
    <Motion key={index} style={style}>
      {({
        translateX, translateY, scale, rotation,
      }) => (
        <div
          onTouchStart={handleTouchStart.bind(null, index, [x, y])}
          onMouseDown={handleMouseDown.bind(null, index, [x, y])}
          className="icon-text"
          role="button"
          tabIndex={index}
          aria-label={name}
          style={{
            WebkitTransform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale}) rotate(${rotation}deg)`,
            transform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale}) rotate(${rotation}deg)`,
            zIndex: index === lastPress ? count : visualPosition,
          }}
        >
          <div
            className="movement-box"
            style={{
              color: background,
              backgroundColor: 'currentColor',
            }}
          >
            <Image />
          </div>
          <span>{name}</span>
        </div>
      )}
    </Motion>
  )
}

export default class Movement extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      mouseXY: [0, 0],
      mouseCircleDelta: [0, 0], // difference in mouse and circle pos for x + y coords for dragging
      lastPress: null, // key of the last pressed component
      isPressed: false,
      isTouched: false,
      order: range(count), // index: visual position. value: component key/id
      rotate: spring(0),
      appNames: screenIcons.map((app) => app.name).concat(Array.from(Array(dockIcons.length))),
      dockSize: dockIcons.length,
      saveMouseY: 0,
      stickyMouseColX: 0,
      stickyMouseColY: 0,
      maxDock: false,
      height: 0,
      width: 0,
      isMoveable: false,
      hasMoved: false,
    }
    this.resize = React.createRef()
  }

  componentDidMount() {
    this._isMounted = true // eslint-disable-line
    window.addEventListener('touchmove', this.handleTouchMove, { passive: false })
    window.addEventListener('mousemove', this.handleMouseMove)
    window.addEventListener('mouseup', this.handleMouseUp)
    window.addEventListener('touchend', this.handleMouseUp)
    const fontSizeToNumber = (fontSizeString) => parseInt(fontSizeString.slice(0, -2), 10)
    const fontSizeNumber = fontSizeToNumber(getComputedStyle(this.resize.current).fontSize)
    const computedWidth = 7.8 * fontSizeNumber
    const computedHeight = 9.3 * fontSizeNumber
    const computedThreshold = 52.6 * fontSizeNumber
    this.mountedSetState({
      width: computedWidth,
      height: computedHeight,
      threshold: computedThreshold,
    })
  }


  componentWillUnmount() {
    this._isMounted = false // eslint-disable-line
  }

  mountedSetState = (state) => {
    if (this._isMounted) { // eslint-disable-line
      this.setState(state)
    }
  }

  handleTouchStart = (key, pressLocation, e) => {
    this.handleMouseDown(key, pressLocation, e.touches[0])
  };

  handleTouchMove = (e) => {
    const { isTouched } = this.state
    if (isTouched) {
      e.preventDefault()
    }
    this.handleMouseMove(e.touches[0])
  };

  handleMouseMove = ({ pageX, pageY }) => {
    const {
      order,
      lastPress,
      isPressed,
      mouseCircleDelta: [dx, dy],
      saveMouseY,
      dockSize,
      stickyMouseColX,
      stickyMouseColY,
      maxDock,
      width,
      height,
      threshold,
    } = this.state

    const changeDock = (y) => {
      if (saveMouseY < threshold) { // started from the top
        if (y > threshold && dockSize < 4) {
          this.resizeDock(dockSize + 1)
          this.mountedSetState({ saveMouseY: y })
        }
      } else if (y < threshold) { // started from the bottom
        this.resizeDock(dockSize - 1)
        this.mountedSetState({ saveMouseY: y })
      }
    }

    const stickyColumns = (x, row) => {
      let currentCol = clamp(Math.round(stickyMouseColX / width), columns)
      let cols
      if (row === 7) {
        cols = range(dockSize).map((n) => {
          const col = (n + (2 - 0.5 * dockSize)) % columns
          return width * col
        })
      } else {
        cols = range(0, columns * width, width)
      }
      const aboveThreshold = cols[clamp(currentCol + 1, 4)]
      const lowerThreshold = cols[currentCol - 1 < 0 ? 0 : currentCol - 1]
      if (x > aboveThreshold || x < lowerThreshold) {
        this.mountedSetState({ stickyMouseColX: x < 0 ? 0 : x })
      }
      const screenRows = Math.ceil((icons.length - dockSize) / columns)
      if (row === screenRows - 1) {
        const maxCol = ((icons.length - dockSize) % columns) - 1
        currentCol = clamp(currentCol, maxCol < 0 ? columns : maxCol)
      }
      return currentCol
    }

    const stickyRows = (y) => {
      let currentRow
      if (y < threshold) {
        const screenRows = Math.ceil((icons.length - dockSize) / columns)
        currentRow = Math.round(stickyMouseColY / height)
        if (currentRow >= screenRows) {
          currentRow = -1
        }
      } else {
        currentRow = 7 // dock row
      }
      const rows = range(0, (Math.ceil(count / columns) + 1) * height, height)
      const aboveThreshold = rows[clamp(currentRow + 1, 6)]
      const lowerThreshold = rows[currentRow - 1 < 0 ? 0 : currentRow - 1]
      if (y > aboveThreshold || y < lowerThreshold) {
        this.mountedSetState({ stickyMouseColY: y < 0 ? 0 : y })
      }
      return currentRow
    }

    if (isPressed) {
      const mouseXY = [(pageX - dx), (pageY - dy)]
      const row = stickyRows(mouseXY[1])
      const col = stickyColumns(mouseXY[0], row)
      let index
      if (row === -1) {
        index = icons.length - dockSize - 1 // last index of screen
      } else if (row === 7) {
        index = col + icons.length - dockSize
      } else {
        index = row * columns + col
      }
      if (maxDock && saveMouseY < threshold) {
        index = clamp(index, icons.length - dockSize - 1)
      }

      const newOrder = reinsert(order, order.indexOf(lastPress), index)
      this.mountedSetState({ mouseXY, order: newOrder })
      changeDock(mouseXY[1])
    }
  };

  handleMouseDown = (key, [pressX, pressY], { pageX, pageY }) => {
    this.mountedSetState({ isTouched: true, saveMouseY: pressY })
    const { removeInteractHint } = this.props
    this.timer = setTimeout(() => {
      this.mountedSetState({ isMoveable: true })
      const { isMoveable, hasMoved } = this.state
      if (isMoveable) {
        if (!this.interval) {
          this.interval = setInterval(this.rotateDiv, 125)
          this.mountedSetState({
            lastPress: key,
            isPressed: true,
            mouseCircleDelta: [pageX - pressX, pageY - pressY],
            mouseXY: [pressX, pressY],
          })
        }
      }
      if (hasMoved === false) {
        this.mountedSetState({ hasMoved: true })
        removeInteractHint()
      }
    }, 500)
    const { isMoveable } = this.state
    if (isMoveable) {
      this.mountedSetState({
        lastPress: key,
        isPressed: true,
        mouseCircleDelta: [pageX - pressX, pageY - pressY],
        mouseXY: [pressX, pressY],
      })
    }
  };

  notMovable = () => {
    clearTimeout(this.timer)
    clearInterval(this.interval)
    this.interval = null
    this.mountedSetState({ isMoveable: false, rotate: spring(0), rotateAngle: 0 })
  }

  toggleAppNames = () => {
    const { order, dockSize } = this.state
    const indices = order.slice(0, icons.length - dockSize)
    const newAppNames = icons.map((app, index) => (indices.includes(index) ? app.name : undefined))
    this.mountedSetState({ appNames: newAppNames })
  }

  handleMouseUp = () => {
    const { dockSize } = this.state
    clearTimeout(this.timer)
    this.toggleAppNames()
    this.mountedSetState({ isTouched: false, isPressed: false, mouseCircleDelta: [0, 0] })
    if (dockSize === 4) {
      this.mountedSetState({ maxDock: true })
    } else {
      this.mountedSetState({ maxDock: false })
    }
  };

  rotateDiv = () => {
    const { rotateAngle } = this.state
    if (rotateAngle === -5) {
      this.mountedSetState({ rotate: spring(5), rotateAngle: 5 })
    } else {
      this.mountedSetState({ rotate: spring(-5), rotateAngle: -5 })
    }
  }

  resizeDock = (dockSize) => {
    this.mountedSetState({ dockSize })
  }

  render() {
    const {
      order, lastPress, isPressed, mouseXY, isMoveable,
    } = this.state
    if (isMoveable) {
      return (
        <>
          <div className="frame">
            {icons.map((value, index) => {
              const { backgroundColor, svg } = value
              const {
                rotate, appNames, height, width, dockSize,
              } = this.state
              return (
                <React.Fragment key={value.name}>
                  <App
                    lastPress={lastPress}
                    isPressed={isPressed}
                    mouseXY={mouseXY}
                    order={order}
                    index={index}
                    handleMouseDown={this.handleMouseDown}
                    handleTouchStart={this.handleTouchStart}
                    Image={svg}
                    background={backgroundColor}
                    rotate={rotate}
                    name={appNames[index]}
                    height={height}
                    width={width}
                    dockSize={dockSize}
                  />
                </React.Fragment>
              )
            })}
          </div>
          <div className="exit" onTouchEnd={this.notMovable} onMouseDown={this.notMovable} role="button" tabIndex="0" aria-label="done">
            <span style={{
              position: 'relative', top: '.2em', fontWeight: 'bold', color: 'black',
            }}
            >
              Done
            </span>
          </div>
        </>
      )
    }
    return (
      <>
        <div ref={this.resize} className="frame">
          {icons.map((value, index) => {
            const {
              backgroundColor, svg, link, to,
            } = value
            const {
              rotate, appNames, height, width, dockSize,
            } = this.state
            const app = (
              <App
                lastPress={lastPress}
                isPressed={isPressed}
                mouseXY={mouseXY}
                order={order}
                index={index}
                handleMouseDown={this.handleMouseDown}
                handleTouchStart={this.handleTouchStart}
                Image={svg}
                background={backgroundColor}
                rotate={rotate}
                name={appNames[index]}
                height={height}
                width={width}
                dockSize={dockSize}
              />
            )
            if (link !== undefined) {
              return (
                <a href={link} target="_blank" rel="noopener noreferrer" key={value.name}>
                  {app}
                </a>
              )
            }
            return (
              <Link to={to} key={to} spy smooth duration={500}>
                {app}
              </Link>
            )
          })}
        </div>
      </>
    )
  }
}

App.propTypes = {
  lastPress: PropTypes.number,
  isPressed: PropTypes.bool.isRequired,
  mouseXY: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  order: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  index: PropTypes.number.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  Image: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
  rotate: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
  handleTouchStart: PropTypes.func.isRequired,
  name: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  dockSize: PropTypes.number.isRequired,
}

App.defaultProps = {
  name: '',
  lastPress: 0,
}

Movement.propTypes = {
  removeInteractHint: PropTypes.func.isRequired,
}
