import React, { Component } from 'react'
import './Phone.css'
import PropTypes from 'prop-types'
import Movement from './react-motion/Movement'

function Power(props) {
  const { on, removeInteractHint } = props
  if (on) {
    return (
      <div className="screen-area">
        <Movement removeInteractHint={removeInteractHint} />
      </div>
    )
  }
  return <div />
}

class Phone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      on: true,
      hasMoved: false,
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({ on: !prevState.on }))
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handleClick()
    }
  }

  removeInteractHint = () => {
    this.setState(() => ({ hasMoved: true }))
  }

  render() {
    const { on, hasMoved } = this.state
    let interactHint
    if (!hasMoved) {
      interactHint = <div className="interact">Edit my screen!</div>
    } else {
      interactHint = <div className="interact fade-out">Edit my screen!</div>
    }
    return (
      <div className="center">
        <div className="phoneBorder">
          <div className="powerButton" />
          <div className="powerButtonClick" onClick={this.handleClick} onKeyUp={this.handleKeyUp} role="button" tabIndex="0" aria-label="Power Button" />
          <div className="topVolumeButton" />
          <div className="bottomVolumeButton" />
          <div className="toggleButton" />
          <div className="phoneShade">
            <div className="phone">
              {interactHint}
              <div className={on ? 'screen screen-background' : 'screen screen-off'}>
                <div className={on ? 'dock' : 'screen-off'} />
                <Power on={on} removeInteractHint={this.removeInteractHint} />
                <div className="notchRight" />
                <div className="notchLeft" />
                <div className="notchCurve" />
                <div className="cameraOutline">
                  <div className="camera">
                    <div className="cameraGlareLeft" />
                    <div className="cameraGlareRight" />
                    <div className="cameraGlareCenter" />
                  </div>
                </div>
                <div className="speaker" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Phone

Power.propTypes = {
  on: PropTypes.bool,
  removeInteractHint: PropTypes.func.isRequired,
}

Power.defaultProps = {
  on: true,
}
