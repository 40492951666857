import React from 'react'
import './About.css'

function About() {
  return (
    <div className="about">
      <h3>
        About
      </h3>
      <p name="about" className="about-text">
        Thanks for visiting my site!
        I&apos;m currently a Software Developer at StateFarm working to
        empower teams through DevOps solutions.
        I built this site with React.js and am passionate about front end design.
        If you have any questions for me or just want to chat, use the form below and
        I&apos;ll get back to you as soon as I can.
      </p>
    </div>
  )
}

export default About
