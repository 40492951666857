import React, { Component } from 'react'
import './Contact.css'

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&')
class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '', email: '', message: '', modal: false,
    }
  }

  handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => this.setState({ modal: true }))
      .catch((error) => alert(error))

    e.preventDefault()
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  closeModal = () => {
    this.setState({ modal: false })
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.closeModal()
    }
  }

  render() {
    const {
      name, email, message, modal,
    } = this.state
    return (
      <form name="contact" onSubmit={this.handleSubmit} data-netlify="true">
        <p className="contact-greeting">
          What can I do for you?
        </p>
        <div className="contact-layout">
          <p>
            <label htmlFor="name">
              Name:
              <br />
              <input className="single-input" type="text" name="name" value={name} onChange={this.handleChange} />
            </label>
          </p>
          <p>
            <label htmlFor="email">
              Email:
              <br />
              <input className="single-input" type="email" name="email" value={email} onChange={this.handleChange} />
            </label>
          </p>
          <p className="message">
            <label htmlFor="message">
              Message:
              <br />
              <textarea name="message" value={message} onChange={this.handleChange} />
            </label>
          </p>
          <p className="submit">
            <button className="send-button input" type="submit">Send</button>
          </p>
        </div>
        <div className={modal ? 'modal-help visible' : 'modal-help invisible'}>
          <div className="modal__content">
            <button className="close-modal" type="button" onClick={this.closeModal} onKeyUp={this.handleKeyUp}>&times;</button>
            <div className="modal__header">
              <p>Message received!</p>
            </div>
            <div className="modal-body">
              <p>Thanks for getting in touch.</p>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default Contact
