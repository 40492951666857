import React from 'react'
import { Element } from 'react-scroll'
import Phone from './Phone'
import About from './About'
import Contact from './Contact'
import './Body.css'

const Body = () => (
  <div className="body">
    <Element name="">
      <div className="font">
        <Phone />
      </div>
    </Element>
    <Element name="about">
      <About />
    </Element>
    <Element name="contact-element">
      <Contact />
    </Element>
    <div className="footer" />
  </div>
)

export default Body
