import React from 'react'

function PawPrint() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80%"
      height="100%"
      version="1.1"
      viewBox="0 0 325.661 281.658"
    >
      <g transform="translate(106.89 77.33)">
        <path
          fill="#000"
          strokeWidth="5.768"
          d="M413.936.002c-12.378-.064-25.36 1.771-38.905 5.4-78.805 21.117-110.757 85.301-78.334 206.303 16.212 60.5 45.173 139.596 96.358 187.926 26.34 24.87 59.986 54.516 99.388 43.959 39.403-10.558 53.72-53.056 64.096-87.764 20.163-67.447 5.695-150.427-10.516-210.928C519.174 44.693 473.573.308 413.936.002zm402.972 0C757.271.308 711.67 44.693 684.82 144.898c-16.21 60.501-30.678 143.481-10.515 210.928 10.375 34.708 24.693 77.206 64.095 87.764 39.403 10.557 73.05-19.089 99.39-43.96 51.184-48.329 80.145-127.424 96.356-187.925 32.423-121.002.472-185.186-78.333-206.303-13.545-3.629-26.527-5.464-38.905-5.4zM106.477 232.35c-17.519.277-35.3 5.854-53.282 16.236-60.572 34.972-72.789 95.213-19.091 188.219 26.848 46.502 68.38 105.574 121.49 134.238 27.33 14.75 61.77 31.833 92.056 14.348 30.286-17.486 32.711-55.854 33.602-86.897 1.73-60.326-28.662-125.83-55.51-172.332-37.755-65.395-77.775-94.47-119.265-93.812zm1017.89 0c-41.49-.658-81.51 28.417-119.265 93.812-26.849 46.503-57.24 112.006-55.51 172.332.89 31.043 3.315 69.41 33.601 86.897 30.287 17.485 64.727.402 92.057-14.348 53.109-28.664 94.642-87.736 121.49-134.238 53.698-93.006 41.48-153.247-19.092-188.22-17.982-10.381-35.763-15.958-53.28-16.235zM615.422 544.047c-213.945 0-387.381 173.437-387.381 387.383 0 213.945 173.436 105.748 387.38 105.748 213.946 0 387.382 108.197 387.382-105.748 0-213.946-173.436-387.383-387.381-387.383z"
          transform="matrix(.26458 0 0 .26458 -106.89 -77.33)"
        />
      </g>
    </svg>
  )
}

export default PawPrint
