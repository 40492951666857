import React from 'react'
import './App.css'
import Header from './components/Header'
import Body from './components/Body'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      darkMode: window.matchMedia('(prefers-color-scheme: light)').matches,
    }
  }

  toggleDarkMode = () => {
    const { darkMode } = this.state
    this.setState({ darkMode: !darkMode })
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.toggleDarkMode()
    }
  }

  render() {
    const { darkMode } = this.state
    return (
      <div className={darkMode ? 'App_light' : 'App_dark'}>
        <Header
          toggleDarkMode={this.toggleDarkMode}
          handleKeyUp={this.handleKeyUp}
          darkMode={darkMode}
        />
        <Body />
      </div>
    )
  }
}

export default App
