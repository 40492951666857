import * as React from 'react'

function SvgComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
    >
      <path
        d="M396.964 386.932h-13.767c-5.854 0-10.6-4.746-10.6-10.6 0-5.852 4.747-10.599 10.6-10.599h13.767c15.788 0 28.632-12.843 28.632-28.632V153.738c0-15.788-12.844-28.632-28.632-28.632H115.036c-15.788.001-28.631 12.845-28.631 28.632v183.363c0 15.788 12.843 28.631 28.631 28.631H266.6c5.854 0 10.6 4.747 10.6 10.6s-4.747 10.6-10.6 10.6H115.036c-27.476 0-49.831-22.353-49.831-49.83V153.738c0-27.476 22.355-49.832 49.831-49.832h281.929c27.476 0 49.831 22.356 49.831 49.832v183.363c-.001 27.478-22.356 49.831-49.832 49.831z"
        fill="#331e68"
      />
      <path
        d="M377.107 457.403c-2.618 0-5.234-.685-7.561-2.054l-25.323-14.915-25.323 14.914a14.93 14.93 0 01-14.954.098 14.93 14.93 0 01-7.504-12.932V415.02c0-5.854 4.747-10.6 10.6-10.6s10.6 4.747 10.6 10.6v16.468l19.02-11.201a14.894 14.894 0 0115.12 0l19.018 11.201v-68.379c0-5.853 4.747-10.6 10.6-10.6s10.6 4.747 10.6 10.6v79.406a14.93 14.93 0 01-7.504 12.932 14.883 14.883 0 01-7.39 1.957z"
        fill="#331e68"
      />
      <path
        d="M344.222 386.965c-33.179 0-60.172-26.993-60.172-60.173 0-33.18 26.993-60.173 60.172-60.173 33.18 0 60.174 26.993 60.174 60.173 0 33.18-26.993 60.173-60.174 60.173zm0-99.145c-21.489 0-38.973 17.483-38.973 38.974 0 21.489 17.484 38.973 38.973 38.973 21.49 0 38.975-17.483 38.975-38.973s-17.483-38.974-38.975-38.974z"
        fill="#331e68"
      />
      <path
        d="M344.223 277.22c14.427 0 27.411 6.168 36.47 16.006.827-.065 1.66-.106 2.503-.106v-95.398c-17.03 0-30.933-13.39-31.76-30.215H160.563c-.826 16.826-14.729 30.215-31.76 30.215v95.398c17.031 0 30.934 13.39 31.76 30.216h134.222c1.777-25.764 23.224-46.116 49.44-46.116z"
        fill="#c9c4e1"
      />
      <path
        d="M294.784 333.934H160.562a10.6 10.6 0 01-10.587-10.082c-.552-11.29-9.853-20.134-21.171-20.134-5.854 0-10.6-4.747-10.6-10.6v-95.397c0-5.854 4.747-10.6 10.6-10.6 11.318 0 20.618-8.844 21.171-20.134.278-5.645 4.934-10.081 10.587-10.081h190.876a10.6 10.6 0 0110.586 10.081c.553 11.29 9.854 20.134 21.172 20.134 5.853 0 10.6 4.747 10.6 10.6v95.398c0 5.853-4.747 10.6-10.6 10.6-.484 0-1.035.024-1.684.074a10.59 10.59 0 01-8.617-3.387c-7.366-8-17.817-12.587-28.673-12.587-20.392 0-37.464 15.92-38.864 36.243a10.597 10.597 0 01-10.574 9.872zm-125.353-21.2h116.293c6.38-26.527 30.399-46.114 58.5-46.114 9.969 0 19.718 2.505 28.372 7.132V206.99c-14.347-3.682-25.791-14.676-30.027-28.883H169.43c-4.237 14.207-15.68 25.201-30.028 28.883v76.863c14.348 3.681 25.791 14.676 30.028 28.883z"
        fill="#331e68"
      />
    </svg>
  )
}

export default SvgComponent
