import * as React from 'react'

function SvgComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 418.951 389.026"
      className="prefix__svg-content"
      width="110%"
      height="110%"
      display="block"
    >
      <path fill="#fdba83" d="M209.738 194.757h51.91v48.202h-51.91z" />
      <path fill="#fdc892" d="M104.869 194.757h51.91v48.202h-51.91z" />
      <path fill="#fc9e6a" d="M104.869 243.446h51.91v48.202h-51.91z" />
      <path fill="#fca772" d="M262.172 194.757h51.91v48.202h-51.91z" />
      <path fill="#fdbf89" d="M209.738 146.067h51.91v48.202h-51.91z" />
      <path fill="#fee1ba" d="M52.434 243.446h51.91v48.202h-51.91z" />
      <path fill="#fca570" d="M262.172 243.446h51.91v48.202h-51.91z" />
      <path fill="#fdd5a3" d="M157.303 194.757h51.91v48.202h-51.91z" />
      <path fill="#fee0b8" d="M314.607 340.824h51.91v48.202h-51.91z" />
      <path fill="#fddbad" d="M262.172 340.824h51.91v48.202h-51.91z" />
      <path fill="#fdcc97" d="M157.303 146.067h51.91v48.202h-51.91z" />
      <path fill="#feeed7" d="M367.041 194.757h51.91v48.202h-51.91z" />
      <path fill="#feeacd" d="M104.869 48.689h51.91v48.202h-51.91z" />
      <path fill="#fff2e0" d="M0 0h51.91v48.202H0z" />
      <path fill="#fedeb3" d="M314.607 146.067h51.91v48.202h-51.91z" />
      <path fill="#fee4c0" d="M157.303 340.824h51.91v48.202h-51.91z" />
      <path fill="#feeed7" d="M262.172 48.689h51.91v48.202h-51.91z" />
      <path fill="#fee0b8" d="M209.738 97.378h51.91v48.202h-51.91z" />
      <path fill="#fee3be" d="M367.041 243.446h51.91v48.202h-51.91z" />
      <path fill="#fdd8a9" d="M157.303 243.446h51.91v48.202h-51.91z" />
      <path fill="#fedfb6" d="M209.738 340.824h51.91v48.202h-51.91z" />
      <path fill="#fee6c4" d="M209.738 243.446h51.91v48.202h-51.91z" />
      <path fill="#fee1ba" d="M157.303 292.135h51.91v48.202h-51.91z" />
      <path fill="#feeacd" d="M367.041 97.378h51.91v48.202h-51.91z" />
      <path fill="#fff1dd" d="M314.607 48.689h51.91v48.202h-51.91z" />
      <path fill="#fedfb6" d="M209.738 292.135h51.91v48.202h-51.91z" />
      <path fill="#feeacd" d="M314.607 194.757h51.91v48.202h-51.91z" />
      <path fill="#fff5e8" d="M0 48.689h51.91v48.202H0z" />
      <path fill="#fff3e2" d="M0 146.067h51.91v48.202H0z" />
      <path fill="#fff5e6" d="M367.041 292.135h51.91v48.202h-51.91z" />
      <path fill="#fedfb6" d="M52.434 146.067h51.91v48.202h-51.91z" />
      <path fill="#fee5c2" d="M314.607 243.446h51.91v48.202h-51.91z" />
      <path fill="#fff2e0" d="M104.869 292.135h51.91v48.202h-51.91z" />
      <path fill="#feefd9" d="M0 243.446h51.91v48.202H0z" />
      <path fill="#feecd3" d="M209.738 0h51.91v48.202h-51.91z" />
      <path fill="#fff3e2" d="M367.041 340.824h51.91v48.202h-51.91z" />
      <path fill="#feecd3" d="M209.738 48.689h51.91v48.202h-51.91z" />
      <path fill="#feeacd" d="M262.172 146.067h51.91v48.202h-51.91z" />
      <path fill="#feebd1" d="M52.434 194.757h51.91v48.202h-51.91z" />
      <path fill="#feefd9" d="M157.303 0h51.91v48.202h-51.91z" />
      <path fill="#feecd3" d="M0 194.757h51.91v48.202H0z" />
      <path fill="#feefd9" d="M314.607 292.135h51.91v48.202h-51.91z" />
      <path fill="#feebcf" d="M157.303 97.378h51.91v48.202h-51.91z" />
      <path fill="#feeed7" d="M262.172 97.378h51.91v48.202h-51.91z" />
      <path fill="#feebd1" d="M314.607 97.378h51.91v48.202h-51.91z" />
      <path fill="#feedd5" d="M157.303 48.689h51.91v48.202h-51.91z" />
      <path fill="#fff0db" d="M104.869 0h51.91v48.202h-51.91z" />
      <path fill="#feefd9" d="M52.434 48.689h51.91v48.202h-51.91z" />
      <path fill="#fee5c2" d="M104.869 97.378h51.91v48.202h-51.91z" />
      <path fill="#fff2e0" d="M52.434 97.378h51.91v48.202h-51.91z" />
      <path fill="#fff0db" d="M262.172 292.135h51.91v48.202h-51.91z" />
      <path fill="#feeed7" d="M104.869 146.067h51.91v48.202h-51.91z" />
      <path fill="#feefd9" d="M367.041 146.067h51.91v48.202h-51.91z" />
      <path fill="#fff3e2" d="M262.172 0h51.91v48.202h-51.91z" />
      <path fill="#fff6ea" d="M0 292.135h51.91v48.202H0z" />
      <path fill="#feefd9" d="M104.869 340.824h51.91v48.202h-51.91z" />
      <path fill="#fff1df" d="M0 97.378h51.91v48.202H0z" />
      <path fill="#fff4e4" d="M367.041 0h51.91v48.202h-51.91z" />
      <path
        fill="#fff5e8"
        d="M367.041 48.689h51.91v48.202h-51.91zM52.434 292.135h51.91v48.202h-51.91z"
      />
      <path fill="#fff6ea" d="M0 340.824h51.91v48.202H0z" />
      <path fill="#fff2e0" d="M52.434 340.824h51.91v48.202h-51.91z" />
      <path fill="#fff5e8" d="M314.607 0h51.91v48.202h-51.91z" />
      <path fill="#fff3e2" d="M52.434 0h51.91v48.202h-51.91z" />
      <text
        style={{
          lineHeight: 1.25,
        }}
        x={-159.424}
        y={202.346}
        fontWeight={400}
        fontSize={40}
        fontFamily="sans-serif"
      />
    </svg>
  )
}

export default SvgComponent
